<template>
    <header class="app-header">
        <h1><router-link to="/"><span class="brand-core-text">JMG</span> <span class="brand-light-text">Groundworks</span></router-link></h1>
    </header>
</template>

<style lang="less">
@import '../core-styles/vars.less';

.app-header {
    background-image: @header-footer-gradient;
    display: flex;
    padding: .5rem 1rem;

    h1 {
        margin-left: 1rem;

        .brand-core-text {
            text-shadow: -1px 0 @brand-light-shade, 0 -1px @brand-light-shade, 1px 0 @brand-light-shade, 0 1px @brand-light-shade;
            font-size: 50px;
        }
        a {
            text-decoration: none;
        }
    }

    .logo {
        height: 100px;
        width: 100px;

        background-image: data-uri("../assets/logowhite.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}
</style>

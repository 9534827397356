import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueGtag from 'vue-gtag';
import store from './store';
import { initializeApp } from 'firebase/app';
import emailJs from '@emailjs/browser';

const firebaseApp = initializeApp({
    apiKey: 'AIzaSyBYxIc3IwjhYuJX7cI8xCgGImReLZsoQE0',
    authDomain: 'jmg-groundworks.firebaseapp.com',
    projectId: 'jmg-groundworks',
    storageBucket: 'jmg-groundworks.appspot.com',
    messagingSenderId: '838129449538',
    appId: '1:838129449538:web:79b0a1c3839d39d1214bdb',
    measurementId: 'G-K3G287F0L4'
});

const app = createApp(App);

emailJs.init('ZVO3LEzTkL3vDNz1L');

app.use(router).use(store);

app.use(VueGtag, {
    config: {
        id: 'G-K3G287F0L4'
    }
}, router);

app.mount('#app');

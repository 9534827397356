<template>
  <footer class="app-footer">
    <div class="copyright-section">
      Copyright &copy; {{ year }}
    </div>
    <div class="tmws-logo"></div>
  </footer>
</template>

<script>
import { computed } from 'vue';

export default {
  setup() {
    const year = computed(() => new Date().getFullYear());
    return { year };
  }
};
</script>

<style lang="less">
@import '../core-styles/vars.less';

.app-footer {
  background-image: @header-footer-gradient;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5rem 1rem;
  color: @brand-light-shade;

  .tmws-logo {
    background-image: data-uri("../assets/taylormadewebsolutions-logo1.png");
    background-size: contain;
    width: 45px;
    height: 30px;
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>

import store from '@/store';
import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'landing',
    redirect: '/page-under-construction'
  },
  {
    path: '/page-under-construction',
    name: 'underConstructionPage',
    component: () => import('../views/UnderConstructionPage.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomePage.vue')
  },
  {
    path: '/contact',
    name: 'contactUs',
    component: () => import('../views/ContactUsPage.vue')
  },
  {
    path: '/previous-work',
    name: 'viewAllPreviousJobs',
    redirect: '/page-under-construction'
    // component: () => import('../views/ViewAllPreviousJobsPage.vue')
  },
  {
    path: '/previous-work/:jobId',
    name: 'viewPreviousJob',
    props: true,
    component: () => import('../views/ViewPreviousJobPage.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'pageNotFound',
    component: () => import('../views/PageNotFoundView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((_to, _from) => {
  store.commit('setLoading', true);
});

export default router;

import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            loading: true
        };
    },
    mutations: {
        setLoading(state: any, payload: boolean) {
            state.loading = payload;
        }
    }
});

export default store;
